var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "newsDetail"
  }, [_c("div", {
    staticClass: "newsDetail-hd"
  }, [_c("div", {
    staticClass: "news-title",
    attrs: {
      "data-catename": "pc_property"
    }
  }, [_vm._v("知识产权声明")])]), _c("div", {
    staticClass: "newsDetail-bd"
  }, [_c("p"), _c("p", [_vm._v("本网站（www.newhorizonbio.com）的所有产品、技术与程序（包括但不限于网页、文字、图片、音频、视频、图表等）均属于诺辉知识产权。诺辉在本服务中提供的内容的知识产权归诺辉所有。上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经诺辉或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。")]), _c("p", [_vm._v("诺辉在本服务中所使用的“常卫清”、“诺辉”、“ColoClear”等商业标识，其著作权或商标权归诺辉所有。“常卫清”、 “诺辉”及相关图形等为诺辉的注册商标。")]), _c("p", [_c("br")]), _c("p", [_vm._v("未经诺辉许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示）使用，或通过非常规方式影响诺辉的正常服务。否则，诺辉将依法追究法律责任。")]), _c("p")]), _c("div", {
    staticClass: "newsDetail-ft"
  }, [_c("a", {
    staticClass: "newsDetail-back",
    attrs: {
      href: "index.html"
    }
  }, [_vm._v("返回首页")])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };